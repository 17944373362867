import React, { useCallback } from "react";
import { getStyles, toAppUrl } from "../../../assets/js/utils/functions";
import { AppBtn } from "../../ui/buttons";
import Typed from "typed.js";
import dynamic from "next/dynamic";
import Portal from "../../portal";
import { useModals } from "../../hooks/useModals";
import PagesLoaderContext from "../../../contexts/page-loader-context";
import { getSizedImage } from "../../../assets/js/utils/utils";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import LazyImage from "../../lazy-image";
import { WHATSAPP_LINK } from "../../../assets/js/utils/constants";
import Link from "next/link";

const FrontPagesHeader = dynamic(() => import("../commons/header"), { ssr: true });
const FrontPagesVideoPlayer = dynamic(() => import("../commons/video-player"), { ssr: true });

const HomePageHeader = () => {
  const { animationHasPlayed } = PagesLoaderContext.useContainer();

  const contentRef = useCallback(
    (node: Element) => {
      if (node !== null) {
        if (!animationHasPlayed) return;

        const typed = new Typed(node, {
          strings: ["Instagram", "Twitter", "Facebook", "Whatsapp", "Social Media"],
          startDelay: 500,
          typeSpeed: 120,
          backSpeed: 80,
          backDelay: 800,
          smartBackspace: true,
          onComplete: () => {
            const typedCursor = document.querySelector(".typed-cursor") as HTMLElement;
            if (typedCursor) typedCursor.style.display = "none";
          },
        });
      }
    },
    [animationHasPlayed]
  );

  return (
    <FrontPagesHeader
      addon={<AnnouncementBadge />}
      isHomePage
      followText="Catlog helps businesses take orders via chat, manage their businesses & get paid easily."
      ctas={<CTAs />}
      heroContent={<FrontPageHero />}
      mobileHero={false}
    >
      <div className="words-line">
        <span className="anim-word-up inline-block" style={getStyles({ "--delay": "0.15s" })}>
          Sell
        </span>{" "}
        <span className="anim-word-up inline-block" style={getStyles({ "--delay": "0.3s" })}>
          easily
        </span>{" "}
        <span className="anim-word-up inline-block" style={getStyles({ "--delay": "0.45s" })}>
          on
        </span>{" "}
      </div>{" "}
      <div className="words-line font-bold text-primary-500">
        <span className="anim-word-up inline-block !leading-tight" style={getStyles({ "--delay": "0.6s" })}>
          <span ref={contentRef} />
          <div className="hidden">Social Media</div>
        </span>
      </div>{" "}
    </FrontPagesHeader>
  );
};

const AnnouncementBadge = () => {
  return (
    <Link href="app/sign-up">
      <a className="flex items-center py-1.5 px-2 sm:px-3.75 sm:py-2.5 bg-white rounded-[40px] group anim-word-up">
        <span className="inline-flex leading-snug text-xxxs sm:text-xs uppercase font-semibold bg-accent-yellow-500 px-2.5 py-1.25 rounded-[30px] text-white">
          NEW
        </span>
        <span className="flex items-center text-black text-xs sm:text-1xs md:text-sm font-medium ml-2.5">
          Create an online store with just your IG page
          {/* prettier-ignore */}
          <svg viewBox="0 0 18 18" fill="none" className="ml-1.25 w-4 sm:w-4.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
          <path d="M3.75 9H14.25" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9 3.75L14.25 9L9 14.25" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </span>
      </a>
    </Link>
  );
};

const CTAs = () => {
  return (
    <>
      <AppBtn size="lg" href={toAppUrl("app/sign-up")} className="!font-semibold py-6 px-10">
        Get started for free
        {/* prettier-ignore */}
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-1.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
          <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="white" />
        </svg>
      </AppBtn>
      <AppBtn
        size="lg"
        color="white"
        href={WHATSAPP_LINK}
        className="border border-grey-outline border-opacity-20 !text-black !font-semibold py-6 px-10"
      >
        I have a question
        {/* prettier-ignore */}
        <svg viewBox="0 0 22 22" fill="none" className="w-4.5 sm:w-5 md:w-[22px] ml-1.5">
          <path d="M16.4804 9.89084V13.5575C16.4804 13.7958 16.4712 14.025 16.4437 14.245C16.2329 16.72 14.7754 17.9483 12.0895 17.9483H11.7229C11.4937 17.9483 11.2737 18.0583 11.1362 18.2417L10.0362 19.7083C9.55038 20.3592 8.76203 20.3592 8.2762 19.7083L7.17619 18.2417C7.05702 18.0858 6.7912 17.9483 6.58953 17.9483H6.22287C3.29871 17.9483 1.83203 17.2242 1.83203 13.5575V9.89084C1.83203 7.20501 3.06954 5.74751 5.53537 5.53667C5.75537 5.50917 5.98454 5.5 6.22287 5.5H12.0895C15.0137 5.5 16.4804 6.96667 16.4804 9.89084Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M20.1468 6.22409V9.89076C20.1468 12.5858 18.9093 14.0341 16.4435 14.2449C16.471 14.0249 16.4802 13.7958 16.4802 13.5574V9.89076C16.4802 6.96659 15.0135 5.49992 12.0893 5.49992H6.22266C5.98432 5.49992 5.75516 5.50909 5.53516 5.53659C5.74599 3.07076 7.20349 1.83325 9.88932 1.83325H15.756C18.6802 1.83325 20.1468 3.29993 20.1468 6.22409Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.3722 12.1458H12.3804" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.16124 12.1458H9.16949" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M5.95312 12.1458H5.96138" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </AppBtn>
    </>
  );
};

const FrontPageHero = () => {
  const { modals, toggleModal } = useModals(["video"]);
  const { screen } = useScreenSize();

  return (
    <>
      <figure className="w-full pt-[75%] sm:pt-[65%] md:pt-[56%] bg-black rounded-[25px] sm:rounded-30 lg:rounded-[40px] overflow-hidden relative safari-overflow-fix">
        <LazyImage
          src={getSizedImage(
            "https://res.cloudinary.com/catlog/image/upload/v1670354764/front-pages/woman-with-cup.webp",
            { [BreakPoint.xs]: "w_750", default: "w_1200" },
            screen
          )}
          showLoader={false}
          alt="Catlog hero video placholder"
          className="w-full absolute top-0"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center">
          <button className="text-white no-outline play-button-ripples" onClick={() => toggleModal("video")}>
            <span className="relative z-50 flex items-center px-2.5 py-1.75 sm:px-3 sm:py-2 bg-accent-yellow-main rounded-30">
              {/* prettier-ignore */}
              <svg className="w-5 sm:w-5.5 lg:w-6" viewBox="0 0 40 40" fill="none">
                <path d="M15.168 20.0005V17.5338C15.168 14.3505 17.418 13.0671 20.168 14.6505L22.3013 15.8838L24.4346 17.1171C27.1846 18.7005 27.1846 21.3005 24.4346 22.8838L22.3013 24.1171L20.168 25.3505C17.418 26.9338 15.168 25.6338 15.168 22.4671V20.0005Z" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.9987 36.6663C29.2034 36.6663 36.6654 29.2044 36.6654 19.9997C36.6654 10.7949 29.2034 3.33301 19.9987 3.33301C10.794 3.33301 3.33203 10.7949 3.33203 19.9997C3.33203 29.2044 10.794 36.6663 19.9987 36.6663Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span className="ml-2 text-sm sm:text-base lg:text-lg font-medium inline-block">Play this video</span>
            </span>
          </button>
        </div>
      </figure>
      <Portal>
        <FrontPagesVideoPlayer show={modals.video.show} toggle={() => toggleModal("video")} ytVideoId="HEP2CEp1i8E" />
      </Portal>
    </>
  );
};

export default HomePageHeader;
